.select-banner {
  .image-banner {
    padding: 120px 0px;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: cover;
    position: relative;

    .MuiBreadcrumbs-ol {
      color: white;
      border-radius: 4px;
      padding: 5px 15px 5px 0px;
      display: inline-flex;
      margin-top: 10px;
      font-size: 14px;
      line-height: 14px;
    } 

    a, p {
      color: white;
      text-decoration: none;
    }
  }

  .title-main {
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
  }

  .container-text {
    position: relative;
    z-index: 3;
  }

  .inner-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    background: rgba(0,0,0,0.2);
  }
}