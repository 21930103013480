.conteiner-main-info {
    display: grid;
    grid-template-columns: 50% auto;
    column-gap: 10px;
    row-gap: 15px;
    margin-top: 50px;

    @media (max-width: 600px) {
       & {
        grid-template-columns: auto;
       }
    }          

    img {
        max-width: 100%;
        position: sticky;
        top: 10vh;
    }
    .conteiner-info{
        font-size: 18px;
        line-height: 24px;
        color: #333333;
    }
}