.container-products-type{
    .title{
        text-align: left;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        background-color: #f4f4f4;
        padding: 8px 0;
    }
    .container-img {
        padding: 10px;
        margin: 0 5px;
        width: 90px;
        img {
            width: 80px;
            height: auto;
        }
    }
    .conteiner-cat {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .category-name{
            font-size: 12px;
            color: #666666;
        }
    }
}