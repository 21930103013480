.experiencia {
    display: grid;
    place-items: center;
    margin-top: 30px;

    @media (max-width: 600px) {
        & {
            font-size: 10px;
        }
    }

    .experiencia__container {
        display: grid;
        place-items: center;
        margin: auto;
        margin-top: 50px;
        width: 100%;
        max-width: 900px;
    }
    
    .experiencia__h2 {
        font-size: 3em;
        font-weight: 400;
        margin-bottom: 10px;
    }
    
    .experiencia__h3 {
        font-size: 1.8em;
        font-weight: 400;
        margin-top: 25px;
        margin-bottom: 10px;
    }
    
    .experiencia__p {
        max-width: 850px;
        font-size: 1.6em;
        text-align: center;
    }
    
    .top {
        margin-top: 20px;
    }
    
    .bot {
        margin-bottom: 20px;
    }
    
    .experiencia__image {
        width: calc(100% - 16px);
        margin: 20px auto;
        display: grid;
        place-items: center;
    }
    
    .experiencia__image-img {
        width: 80%;
        max-width: 1150px;
        margin-bottom: 35px;
    }
    
    .experiencia__image-button {
        text-align: center;
        margin-top: 20px;
    }
    
    .experiencia__image-button a {
        display: inline-block;
        font-size: 1.8em;
        color: #000;
        border: 1px solid #000;
        background: #fff;
        padding: 8px 20px;
        text-decoration: none;
    }
}