.description-section {

    @media (max-width: 600px) {
        & {
            font-size: 10px;
        }
    }

    .description {
        width: 100%;
        max-width: 800px;
        margin-top: 40px;
    }
    
    .description__img {
        text-align: center;
        padding: 20px 0 30px;
    }
    
    .description__img img {
        width: 100px;
    }
    
    .description__title h2 {
        font-family: Roboto;
        font-size: 2em;
        text-align: center;
        font-weight: 400;
        text-transform: uppercase;
    }
    
    .description__content p {
        font-family: Roboto;
        font-size: 1.6em;
        font-weight: 300;
        text-align: center;
        padding: 30px 0;
    }
}