.contaner-grid {
    margin-top: 80px;
    .img-StackGrid {
        padding: 10px;
        cursor: pointer;
        transition: all 500ms;

        &:hover {
            transform: scale(1.05);
        }

        img {
            width: 100%;
        }
    }
}

.react-viewer.react-viewer-transition {
    z-index: 99;
    position: relative;
}