
.select-instagram {
  .container-instagram {
    display: block;

    @media (min-width: 600px) {
      & {
        display: flex;
      }

      & > div {
        width: 30%;
      }
    }

    .link-instagram-image {
      display: flex;
    }

    .container-image {
      position: relative;
      cursor: pointer;
      display: grid;
      a {
        display: flex;
      }
      img {
        width: 100%;
      }
      .icon {
        opacity: 0;
        position: absolute;
        color: white;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
      }
      &:hover .icon {
        opacity: 1;
        z-index: 9;
        svg {
          width: 50px;
          height: 50px;
        }
      }
    }

    .container-image::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #e50064;
      opacity: 0;
      visibility: hidden;
      z-index: 1;
      -webkit-transition: .4s;
      -moz-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
    }

    .container-image:hover::before {
      visibility: visible;
      opacity: .3;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
  }

  .section-title {
    padding: 45px;
    a {
      text-decoration: none;
    }

    h2 {
      font-size: 40px;
      display: block;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 20px;
      text-transform: uppercase;

      font-family: "Poppins",sans-serif;
      color: #1a1a1a;
      margin-top: 0px;
      font-style: normal;
    }

    .border-btn {
      border: 1px solid #D70056;
      color: #D70056;
      background: none;
      border-radius: 0px;
      text-transform: uppercase;
      padding: 17px 42px;
      -moz-user-select: none;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 0;
      position: relative;
      transition: color 0.4s linear;
      position: relative;
      overflow: hidden;
      margin: 0;
    }

    .border-btn::before {
      border: 1px solid transparent;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 101%;
      height: 101%;
      background: #D70056;
      z-index: -1;
      transition: transform 0.5s;
      transition-timing-function: ease;
      transform-origin: 0 0;
      transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
      transform: scaleY(0);
      border-radius: 0;
    }

    a:hover {
      color: #fff;
    }

    .border-btn:hover::before {
      transform: scaleY(1);
      border: 1px solid transparent;
      color: #fff;
    }
  }
}