.product-component {
    .photoheader{
        position: relative;
        background-position: 50% 60%;
        background-size: cover;
        min-height: 50vh;
        .conteinerTitle{
            position: absolute;
            bottom: 10px;
            left: 0px;
            width: 100%;
            h1{
                color: #fff;
                font-size: 80px;
                line-height: 100px;
                bottom: 20px;
                left: 0px;
                font-weight: 400;
                margin: 0;
                @media (max-width: 600px) {
                    & {
                        font-size: 40px !important;
                    }
                }                  
            }
        }
    }
}