.footer {
  width: 100%;
  background: #222;

  a {
    color: white;
    text-decoration: none;
    &:hover {
      color: #eee
    }
  }

  .footer__bottom {
    padding: 80px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 1em;
    font-weight: 300;
    color: #eee;
    gap: 30px;

    @media (max-width: 600px) {
      & {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .footer__bottom-center {
    width: 100%;
  }
  
  .footer__bottom-center a {
    font-size: 1em;
    font-weight: 300;
    text-decoration: none;
    color: #eee;
  }
  
  .footer__bottom-right p {
    margin-bottom: 0;
  }
  
  .footer__bottom-right-input {
    height: 35px;
    border: none;
    padding-left: 10px;
  }
  
  .footer__bottom-right-button {
    height: 37px;
    width: 100px;
    margin-left: 2px;
    border-radius: 0;
    border: none;
    font-family: 'Roboto';
    color: #eee;
    background: #333;
    cursor: pointer;
  }
  
  .footer__bottom-right-form {
    width: 250px;
    display: grid;
    grid-template-columns: 30px auto;
  }
  
  .footer__bottom-right-form input {
    width: 14px;
    margin-top: 20px;
    margin-left: 0;
    align-self: start;
  }
  
  .footer__bottom-right-form p {
    align-self: start;
    margin-top: 14px;
  }
  
  .footer__bottom-right-auth a {
    color: #eee;
  }
}