.downloadable {
    background: #717171;
    padding: 10px 0px;
    margin-top: 80px;
    .downloadable-buttons {
        display: flex;
        justify-content: space-around;
        a {
            display: block;
            color: #fff;
            padding: 10px 20px;
            text-decoration: none;
            font-size: 16px;
        }
    }
}   