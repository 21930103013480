.header {
  display: grid;
  background: #333;
  padding: 0;

  .container-menu-icon {
    background-color: white;
  }

  .header__icon {
    width: 35px;
    height: 35px;
    position: relative;
    bottom: 2px;
    margin-right: 25px;
    cursor: pointer;
  }

  .header__menu {
    position: absolute;
    left: 100px;
    top: 34px;
    z-index: 5;
  }

  .header__title {
    position: relative;
    width: calc(100% - 16px);
    text-align: center;
    height: 90px;
    top: 75px;
  }

  .header__title img {
    width: 500px;
    vertical-align: middle;
  }

  .header__title h1 {
    font-size: 1em;
    font-weight: 400;
    color: #ddd;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .photogallery_homepage {
    width: 100%;
    background-color: white;
    display: block;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: 2;
    min-height: 800px;
    height: 70vh;
  }

  @media (max-width: 1020px) {
    .photogallery_homepage {
      width: 50%;
      height: auto;
      min-height: auto;
    }
  }

  .photogallery_images {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .photogallery_images img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  

  .logopayoff {
    top: 50%;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0px;
    z-index: 5;

    & img {
      width: 100%;
      max-width: 700px;

      @media (max-width: 600px) {
        & {
          width: 250px;
        }
      }
    }
  }

  h1 {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 30px;
    letter-spacing: 9px;
    font-weight: 500;
    color: #ddd;

    @media (max-width: 600px) {
      & {
        font-size: 16px;
        letter-spacing: 3px;
      }
    }
  }
}

.MuiBreadcrumbs-separator {
  margin: 0;
}

.header__list {
  .MuiBreadcrumbs-li {
    margin: 0 10px;
  }
}

.container-menu {
  text-transform: uppercase;
  .submenu {
    display: none;
    position: absolute;

    .submenu-link {
      background-color: rgba(0, 0, 0, 0.2);
      padding: 5px 10px;
      margin: 2px 0;
      font-size: 14px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  &:hover .submenu {
    display: block;
  }
}
