.about-component {
  p {
    text-align: left;
    color: #646D77;
  }

  .img-note {
    width: 100%;
  }

  .img-logo{
    max-width: 450px;
    margin: 10px auto;
    align-self: center;
  }
}
