.contact-component {
    .contact-title {
      font-size: 27px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  
    .map {
      width: 100%;
      height: 600px;
    }
  
    .media {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  
    .contact-info__icon {
      margin-right: 20px;
    }
  
    .contact-info__icon i, .contact-info__icon span {
      color: #8f9195;
      font-size: 27px;
    }
    
    .media-body {
      -ms-flex: 1;
      flex: 1;
    }
  
    .media-body h3 {
      font-size: 16px;
      margin-bottom: 0;
      font-size: 16px;
      color: #2a2a2a;
    }
  
    .contact-info {
      margin-bottom: 25px;
    }
  
    .contact-info .media-body p {
      color: #8a8a8a;
    }
  
    p {
      font-family: "Poppins",sans-serif;
      color: #646D77;
      font-size: 14px;
      line-height: 30px;
      margin-bottom: 15px;
      font-weight: normal;
      line-height: 1.4;
      margin: 0;
    }
  
    h3 {
      color: #1a1a1a;
      margin-top: 0px;
      font-style: normal;
      font-weight: 500;
      text-transform: normal;
    }
  
    .container-contact {
      display: grid;
      grid-template-columns: 100%;
      column-gap: 40px;
      row-gap: 40px;
  
      @media (min-width: 600px) {
        & {
          grid-template-columns: calc(70% - 20px) calc(30% - 20px);
        }
      }
    }
  
    .MuiFormLabel-root, .MuiFormLabel-root.Mui-focused {
      color: black;
    }
    .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #aaa;
    }
    .MuiInputBase-input {
      font-size: 1.5em;
      color: #646D77;
      line-height: 1.5;
    }
    .input-form {
      margin-bottom: 1em;
    }
  
    .w-100 {
      width: 100%;
    }
  
    .MuiInput-underline:before {
      border-bottom: solid 1px #aaa;
    }
  
    .MuiInput-underline:after {
      border-bottom: solid 2px #aaa;
    }
    .MuiFormLabel-root {
      color: #646D77;
    }
  
    .btn-submit {
      border: solid 3px #aaa;
      color: #aaa;
      font-size: 1.3em;
      padding: .5em 3em;
      border-radius: 15px;
    }
    .btn-submit:hover {
      border: solid 3px #aaa;
      background-color: #aaa;
      color: white;
      font-size: 1.3em;
      padding: .5em 3em;
      border-radius: 15px;
    }
  
    .container-btn-send{
      display: flex;
      margin-top: 2em;
      justify-content: flex-end;
  
      .reserva {
        font-size: 1.5em;
        display: flex;
        align-items: center;
        margin-right: .5em;
      }
    }
}

.product-component {
    .photoheader{
        position: relative;
        background-position: 50% 60%;
        background-size: cover;
        min-height: 50vh;
        .conteinerTitle{
            position: absolute;
            bottom: 10px;
            left: 0px;
            width: 100%;
            h1{
                color: #fff;
                font-size: 80px;
                line-height: 100px;
                bottom: 20px;
                left: 0px;
                font-weight: 400;
                margin: 0;
            }
        }
    }
}