.category-card {
  position: relative;
  overflow: hidden;

  height: 400px;
  min-width: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  @media (max-width: 600px) {
    & {
      min-width: 100%;
      height: 300px;
      min-width: 300px;
    }
}

  img {
    display: block;
    width: 100%;
    height: 400px;
    vertical-align: middle;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  .container-title {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 1.2em 1.6em;

    .title {
      position: absolute;
      bottom: 0;
      left: 0px;
      margin: 0;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      color: #fff;
      text-transform: uppercase;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #111;
    opacity: 0.4;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  &:hover {
    img {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }

    .title {
      bottom: 30px;
      opacity: 0;
    }

    &:after {
      opacity: 0;
    }
  }
}