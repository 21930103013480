
.blog-component {
  background-color: #eee;
  
  .container-banner {
    margin-bottom: 50px;
  }

  .container-news-list {
    margin-bottom: 50px;
  }

  .btn-all-note {
    background-color: #333;
  }
}

.loadView {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(153, 0, 127, 0.356);
  z-index: 999;
  display: none;
  &.show {
    display: block;
  }
}