.catalog-component {
    .container-section {
        .header-catalog {
            padding: 50px 0 ;
            background-color: #007e81;
            color: white;

            li {
                color: white;
                font-size: 14px;
            }
            h1 {
                text-align: left;
                margin: 0 0 5px 0;
                font-size: 36px;
            }
        }
    }
}