.container-footer-marcas {
  background: #eee;

  h2 {
    border-bottom: 1px solid #000;
    padding-bottom: 15px;
  }

  .footer__top {
    box-sizing: border-box;
    padding: 50px 0px;
    margin: auto;
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media (max-width: 600px) {
      & {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  
  .footer__top-empresa h2,
  .footer__top-productos h2,
  .footer__top-info h2 {
    margin-bottom: 10px;
    font-size: 1.3em;
  }
}