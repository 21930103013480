.save-images {
  & > div {
    & > div {
      width: 100%;
      max-width: 100% !important;
    }
  }
}
.image-uploaded {
  display: inline-block;
  width: calc(20% - 10px);
  margin-bottom: 2em;
  margin-right: 10px;
  .title {
    text-align: center;
    margin-bottom: 5px;
  }
  img {
    max-width: 100%;
    max-height: 100px;
    margin: auto;
    display: block;
  }

  .btn-images {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    text-align: center;
  }

  .btn-images-note {
    background-color: rgb(3, 97, 156);
    color: white;
    border-radius: 5px;
    transition: all 250ms;
    & > div {
      padding: 5px;
    }
    &:hover {
      background-color: #1eb0f1;
    }
  }
}

.container-copy-image {
  cursor: pointer;
}

.MuiSnackbarContent-message {
  width: 100%;
  #message-id {
    display: inline-block !important;
    width: 100%;
    text-align: center !important;
  }
}

.circularProgress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(212, 212, 212, 0.35);
  z-index: 9999;
  display: none;
  &.activated {
    display: block;
  }
  .MuiCircularProgress-root {
    top: 50%;
    position: absolute;
    left: 50%;
  }
}
