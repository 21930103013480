.patrimonio-section {
  .container-info-main {
    text-align: center;
    font-size: 25px;
    max-width: 800px;
    margin: auto;
    font-weight: 400;
    margin-top: 75px;
  }

  .image-right {
    img {
      width: 100%;
    }
  }

  .container-year {
    position: relative;
    .info-left {
      text-align: right;
      max-width: 390px;
      margin-left: auto;
      font-size: 18px;
  
      &.width-full{
        max-width: 100%;
      }

      &.text-left {
        text-align: left;
      }
  
      h1 {
        font-size: 98px;
      }
  
      .text-absolute {
        position: absolute;
        background-color: white;

        @media (max-width: 600px) {
          & {
            position: relative;
          }
        }
      }
    }
  }

  .year-background {
    height: 1080px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;

    @media (max-width: 600px) {
      & {
        height: 500px;
      }
    }

    .info-left {
      color: white;
      position: absolute;
      bottom: 100px;
      left: 20%;
    }

    .info-center {
      background-color: rgba(255,255,255,0.8);
      text-align: center;
      padding: 50px;
      left: calc(50% - 340px);
      bottom: 100px;
      position: absolute;

      @media (max-width: 600px) {
        & {
          max-width: 100%;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
}