.quadrifoglio-group-section {
  .description {
    text-align: center;
    font-size: 26px;
    line-height: 1.2;
    color: #444;
    padding: 50px 0px 20px;
    margin: auto;
    font-weight: 400;
    max-width: 600px;

    @media (max-width: 600px) {
      & {
        font-size: 20px;
        line-height: 30px;
        padding: 50px 0px 20px;
      }
    }
  }

  .description-info-section-1 {
    max-width: 600px;
    margin: 40px auto 0 auto;
  }

  .icons-info-section-1 {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      margin-top: 10px;
      width: 100px;
      margin-left: 15px;
    }
  }

  .container-section-info {
    position: relative;
    margin-top: 100px;
    height: 800px;

    h2 {
      font-size: 36px;
      line-height: 36px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .line80w {
      display: block;
      height: 1px;
      width: 80px;
      border-bottom: 3px solid #fff;
      margin: 15px 0;
      padding: 0;
    }

    .info-left {
      position: relative;
      z-index: 2;

      width: 38%;
      box-sizing: border-box;
      margin-left: 15px;
      padding: 60px;
      background-color: #3a3a3a;
      color: #fff;
      font-size: 18px;
      line-height: 26px;
    }

    .info-right {
      position: relative;
      z-index: 2;

      width: 38%;
      box-sizing: border-box;
      margin-right: 15px;
      margin-left: auto;
      padding: 60px;
      background-color: #3a3a3a;
      color: #fff;
      font-size: 18px;
      line-height: 26px;
    }

    .image-right {
      position: absolute;
      z-index: 1;
      right: -15px;
      width: 83%;
      float: left;
      top: -72px;
      height: 100%;

      iframe {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
      }
    }

    .image-left {
      position: absolute;
      z-index: 1;
      left: 15px;
      width: 83%;
      float: left;
      top: -72px;
      height: 100%;
      overflow: hidden;

      iframe {
        width: 100%;
        height: 100%;
      }

      & > .image-column {
        display: flex;
        span:first-child {
          margin-top: 100px;
          @media (max-width: 600px) {
            & {
              margin-top: 0;
            }
          }
        }
        @media (max-width: 600px) {
          span:last-child {
            display: none;
          }
        }
        span > img {
          margin-right: 20px;
          max-width: 700px;
          width: 100%;
        }
      }
    }

    .img-slider {
      background-position: 50% 80%;
      height: 800px;
    }

    .carousel-slider {
      .control-dots {
        padding-left: 30px;
        text-align: left;
      }
      .dot {
        background-color: black;
        opacity: 1;
        width: 30px;
        height: 4px;
        border-radius: 0;
      }

      .dot.selected {
        background-color: white;
        opacity: 1;
      }
    }

    @media (max-width: 600px) {
      & {
        margin-top: 70px;
        margin-bottom: 50px;
        height: auto;

        .info-left {
          position: relative;
          z-index: 2;
          width: 100%;
          padding: 20px;
          margin-left: 0;
        }

        .info-right {
          position: relative;
          z-index: 2;
          width: 100%;
          padding: 20px;
        }

        .image-right {
          position: relative;
          z-index: 1;
          right: 0;
          width: 100%;
          float: inherit;
          top: 0px;
          height: 100%;
          margin-top: 25px;
    
          iframe {
            width: 100%;
            height: 100%;
          }
        }

        .image-left {
          position: relative;
          z-index: 1;
          right: 0px;
          left: 0;
          width: 100%;
          float: inherit;
          top: 0px;
          height: auto;
          margin-top: 25px;
    
          iframe {
            width: 100%;
            height: 100%;
          }
        }

        .img-slider {
          background-position: 50% 80%;
          background-size: cover;
          background-repeat: no-repeat;
          height: 300px;
        }
      }
    }
  }

  video {
    width: 100%;
  }
}