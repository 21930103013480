.certificate-container {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 60px);
    margin: 0 auto;
    justify-content: space-evenly;
}

.certificate-card {
    margin: 10px;
    margin-top: 50px;
    a {
        text-decoration: none;
    }
}