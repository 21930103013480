
.select-note-horizontal {
  transition: all 0.5s;

  &:hover {
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.5s;
  }

  .line80 {
    display: block;
    height: 1px;
    width: 80px;
    border-bottom: 3px solid #000;
    margin: 15px 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  h2 {
    color: black;
    font-size: 40px;
    line-height: 1;
    font-weight: 300;

  }
  
  .image-note {
    height: 100%;
    min-height: 250px;
    background-size: cover;
    background-position: 50%;
  }

  .container-text {
    background-color: white;
    height: 100%;
  }

  .summarize {
    color: #7b7b7b;
  }

  .read-all {
    color: black;
  }
}