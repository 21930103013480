
.container-textiles {
    margin-top: 80px;

    .box-textiles {
        position: relative;
        .bar-gray {
            border-bottom: solid 1px #ddd;
            width: 100%;
            height: 1px;
            bottom: 0px;
            position: absolute;
            z-index: -1;
        }
    }

    .title-textiles h2{
        text-align: center;
        color: #333;
        padding: 20px, 0px,50px;
        font-size: 32px;
    }
    .bar-textiles {
        background-color: transparent;
        box-shadow: none;
        margin-top: 50;

        .MuiTabs-indicator {
            display: none;
        }
    }

    .tab-btn {
        color: black;
        border-bottom: solid 1px #ddd;
        &:hover {
            background-color: #eee;
        }

        &.Mui-selected {
            border-top: solid 1px #ddd;
            border-right: solid 1px #ddd;
            border-left: solid 1px #ddd;
            border-bottom: solid 1px white;

            &.Mui-selected:hover {
                background-color: white;
            }

            &::after {
                content: '';
                width: 100%;
                height: 2px;
                bottom: 0;
                position: absolute;
            }
        }
    }

    .tab-panel-info {
        & > div {
            padding: 0;
        }
    }

    .image-textiles {
        display: flex;
        flex-wrap: wrap;
        padding-top: 30px;
        & > div {
            min-width: 151px;
            height: 151px;
            margin-bottom: 20px;
            margin-right: 20px;
        }
        
        .conatiner-image-textil {
            position: relative;
            background-size: cover;
        }

        .thumb-finiture-title{
            background: #ffffffcc;
            bottom: 2px;
            left: 2px;
            color: black;
            display: inline-block;
            font-size: 12px;
            line-height: 12px;
            padding: 8px 12px;
            position: absolute;
        }
    }
}