body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$sizes: 12;


@for $i from 0 through $sizes {
  $padding: $i * 0.50rem;
  /* padding #{$padding} */
  .p-#{$i} {padding: $padding;}
  .pl-#{$i} {padding-left: $padding;}
  .pr-#{$i} {padding-right: $padding;}
  .pt-#{$i} {padding-top: $padding;}
  .pb-#{$i} {padding-bottom: $padding;}
  .px-#{$i} {padding-left: $padding; padding-right: $padding;}
  .py-#{$i} {padding-top: $padding; padding-bottom: $padding;}
}

@for $i from 0 through $sizes {
  $margin: $i * 0.50rem;
  /* margin #{$margin} */
  .m-#{$i}  {margin: $margin;}
  .ml-#{$i} {margin-left: $margin;}
  .mr-#{$i} {margin-right: $margin;}
  .mt-#{$i} {margin-top: $margin;}
  .mb-#{$i} {margin-bottom: $margin;}
  .mx-#{$i} {margin-left: $margin; margin-right: $margin;}
  .my-#{$i} {margin-top: $margin; margin-bottom: $margin;}
}
