
.bm-menu-wrap {
  width: 40% !important;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;

  @media (max-width: 600px) {
    & {
      width: 80% !important;
    }
  }

  .bm-menu {
    .container-logo-30 {
      margin-bottom: 35px !important;
      img {
        width: 100%;
        max-width: 100px;
      }
    }
  
    .bm-item-list {
      overflow: auto;
    }
  
    .bm-item {
      display: block;
      color: #fff;
      text-align: right;
      margin: 15px;
      color: black;
  
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  
      a {
        text-decoration: none;
        color: black;
      }
    }
  
    .container-menu {
      text-transform: uppercase;
      .submenu {
        display: block !important;
        position: relative !important;
  
        .submenu-link {
          background-color: white !important;
          padding: 5px 0px;
          margin: 2px 0;
          font-size: 14px;
        }
      }
    }
  
    .title-section {
      margin: 0;
      color: #000;
      font-weight: bold;
      border-bottom: solid black 1px;
    }
  }
}