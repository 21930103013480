.products-list-component {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    width: calc(25% - 20px);
    min-width: 400px;
    padding: 10px;
    box-sizing: content-box;

    @media (max-width: 600px) {
      & {
        width: 100%;
        min-width: 100%;
        padding: 10px;
      }
    }
  }
}